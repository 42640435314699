import React, { useState, useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import makeAsyncScriptLoader from 'react-async-script';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Icon from '../components/Icon';
import GetStarted from '../components/GetStarted';
import FAQ from '../components/FAQ';
import Scrollspy from '../components/Scrollspy';
import '../styles/payment-gateway.scss';
import '../styles/components/content-image-block.scss';
import '../styles/components/feature-heading.scss';
import '../styles/components/gateway.scss';
import '../styles/components/other-products.scss';
import '../styles/components/usecases.scss';
import '../styles/section-features.scss';
import BusinessSlider from '../components/Sliders/BusinessSlider/BusinessSlider';
import {
  superChargeTabData,
  businessPaymentLinks,
  upiPayments,
  paymentModes,
  centralisedControlList,
  faqData,
  integrations,
  otherProducts,
  useCases,
  bestPaymentGateway,
  lowPricingChecklist,
} from '../content/payment-gateway-content';
import LogoMarquee from '../components/LogoMarquee/LogoMarquee';
import paymentGateways from '../utils/paymentGateways';
import '../styles/components/section-features.scss';
import '../styles/components/section-developers.scss';
import Tabs from '../components/Tabs/Tabs';
import TransparentVideo from '../components/TransparentVideo/TransparentVideo';

const PaymentGateway = ({ title, image, imageWidth }) => (
  <div className="styled-payment-gateway">
    {!image.startsWith('/img/payment-gateways') ? (
      paymentGateways[image]
    ) : (
      <img src={image} alt={title} width={imageWidth} loading="lazy" />
    )}
    <span>{title}</span>
  </div>
);

PaymentGateway.defaultProps = {
  imageWidth: '38px',
};

PaymentGateway.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  imageWidth: PropTypes.string,
};

const PaymentGatewayPage = ({ lottie, ...rest }) => {
  const featuresRef = useRef();
  const cardsContainerRef = useRef();

  const [animBestUpiPayment, setAnimBestUpiPayment] = useState(0);

  const updateAnimBestUpiPayment = useCallback(() => {
    setAnimBestUpiPayment((state) => (state === 3 ? 0 : state + 1));
  }, []);

  useEffect(() => {
    const interval = setInterval(() => updateAnimBestUpiPayment(), 2000);

    return () => clearInterval(interval);
  }, [updateAnimBestUpiPayment]);

  const PgHero = useRef();

  return (
    <Layout
      lightHeader
      {...rest}
      signInLink="https://merchant.cashfree.com/merchant/login?source-action=PG%20Page&action=Sign%20In&button-id=SignIn_Navbar"
      createAccountLink="https://merchant.cashfree.com/merchants/signup?source-action=PG%20Page&action=Sign%20Up&button-id=StartNow_Navbar">
      <SEO
        title="Best Payment Gateway in India - Cashfree Payments"
        description="Cashfree Payment Gateway offers 120+ payment modes. Enjoy highest success rates, instant settlements, 24*7 support, and zero set-up fee."
        url="https://www.cashfree.com/payment-gateway-india/"
        keywords={[
          'payment gateway',
          'India payment gateway',
          'best payment gateway india',
          'online payment gateway',
          'best payment gateway',
          'payment gateway for website',
        ]}
      />
      <section className="payment-gateway-main">
        <div>
          <div className="payment-gateway-hero text-white">
            <div className="container position-relative z-10 ">
              <div className="flex flex-wrap lg:flex-nowrap justify-start lg:justify-between lg:w-[120%]">
                <div className="w-full lg:w-2/5 pb-0 lg:self-center">
                  <h1 className="text-sm font-semibold text-white pl-5 mb-[10px] uppercase relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[3px] font-body">
                    Payment Gateway
                  </h1>

                  <h4 className="mb-[12px] md:mb-[20px] text-xl md:text-2xl font-semibold max-w-[590px] font-heading">
                    Payment Gateway for your website and mobile app
                  </h4>
                  <p className="text-opacity-80 max-w-[600px] text-[16px] leading-[24px] md:text-2.5md  font-body">
                    Accept online payments and grow your business. Delight
                    customers with best success rates on mobile and UPI, and
                    access payment gateway advanced features.
                  </p>

                  <ul className="p-0 mt-[24px] md:mt-[28px] mb-4 md:mb-[48px] list-none max-w-full flex flex-wrap flex-row md:items-center justify-start">
                    {[
                      {
                        id: 0,
                        listItem: 'Instant settlements',
                      },
                      {
                        id: 1,
                        listItem: 'Instant refunds',
                      },
                      {
                        id: 2,
                        listItem: 'Pre-authorisation',
                      },
                    ].map(({ id, listItem }) => (
                      <li
                        key={id}
                        className="mb-[15px] items-center md:mb-0 mr-[24px] lg:mr-4 xl:mr-[24px] last:mr-0 flex">
                        <span className="w-5 h-5 flex flex-none justify-center items-center rounded-full mr-2 bg-white  bg-opacity-25">
                          <Icon
                            name="tick"
                            width="9.21px"
                            height="6.29px"
                            fill="#fff"
                          />
                        </span>
                        <h3 className="font-body !m-0 text-2.5sm md:text-md">
                          {listItem}
                        </h3>
                      </li>
                    ))}
                  </ul>
                  <div className="flex flex-wrap md:flex-nowrap justify-between md:justify-start">
                    <a
                      className="button button-green w-full  md:w-auto mb-4 md:mb-0 mr-0 md:mr-4"
                      href="https://merchant.cashfree.com/merchants/signup?source-action=PG%20Page&action=Sign%20Up&button-id=StartNow_Hero">
                      Create Account <span className="chevron" />
                    </a>
                    <a
                      className="button button-outline no-shadow text-white border-cf-stroke w-full md:w-auto"
                      href="https://www.cashfree.com/contact-sales/?source-action=PG%20Page&action=Contact%20Sales&button-id=ContactSales_Hero">
                      Contact Sales <span className="chevron !mb-0" />
                    </a>
                  </div>
                </div>
                <div className="w-full lg:w-3/5 lg:pl-[19px] h-full hidden md:block">
                  <TransparentVideo
                    containerClass=" lg:left-[1%]"
                    backgroundRef={PgHero}
                    className="relative z-10"
                    poster="/img/payment-gateways/pg-hero-video.png"
                    width="1130"
                    height="800"
                    mp4Src="/img/payment-gateways/pg-hero-video.mp4"
                    webmSrc="/img/payment-gateways/pg-hero-video.webm"
                  />
                </div>
                <div className="w-full h-full block md:hidden mt-[28px]">
                  <TransparentVideo
                    containerClass=" "
                    backgroundRef={PgHero}
                    className="relative z-10"
                    poster="/img/payment-gateways/pg-hero-mweb-poster.png"
                    width="1130"
                    height="800"
                    mp4Src="/img/payment-gateways/pg-hero-mweb.mp4"
                    webmSrc="/img/payment-gateways/pg-hero-mweb.webm"
                  />
                </div>
              </div>
            </div>
            <div className="hidden md:block max-w-[1100px] mx-auto mb-[64px]">
              <div className="flex justify-between items-center relative z-10">
                {[
                  {
                    id: 0,
                    src: '/img/payment-gateways/logo/irctc.svg',
                    alt: 'IRCTC',
                  },
                  {
                    id: 1,
                    src: '/img/payment-gateways/logo/easemytrip.svg',
                    alt: 'easemytrip',
                  },
                  {
                    id: 2,
                    src: '/img/payment-gateways/logo/nykaa.svg',
                    alt: 'nykaa',
                  },
                  {
                    id: 3,
                    src: '/img/payment-gateways/logo/ixigo.svg',
                    alt: 'ixigo',
                  },
                  {
                    id: 4,
                    src: '/img/payment-gateways/logo/delhivery.svg',
                    alt: 'delhivery',
                  },
                  {
                    id: 5,
                    src: '/img/payment-gateways/logo/cred.svg',
                    alt: 'cred',
                  },
                  {
                    id: 6,
                    src: '/img/payment-gateways/logo/zomato.svg',
                    alt: 'zomato',
                  },
                ].map(({ id, src, alt }) => (
                  <img src={src} alt={alt} key={id} loading="lazy" />
                ))}
              </div>
            </div>
            <div className="mb-0 md:mb-[36px] mt-[20px] md:mt-[56px] block md:hidden relative z-10 overflow-hidden">
              <LogoMarquee
                data={[
                  {
                    id: 0,
                    src: 'img/payment-gateways/logo/irctc.svg',
                    alt: 'IRCTC',
                  },
                  {
                    id: 1,
                    src: 'img/payment-gateways/logo/easemytrip.svg',
                    alt: 'easemytrip',
                  },
                  {
                    id: 2,
                    src: 'img/payment-gateways/logo/nykaa.svg',
                    alt: 'nykaa',
                  },
                  {
                    id: 3,
                    src: 'img/payment-gateways/logo/ixigo.svg',
                    alt: 'ixigo',
                  },
                  {
                    id: 4,
                    src: 'img/payment-gateways/logo/delhivery.svg',
                    alt: 'delhivery',
                  },
                  {
                    id: 5,
                    src: 'img/payment-gateways/logo/cred.svg',
                    alt: 'cred',
                  },
                  {
                    id: 6,
                    src: 'img/payment-gateways/logo/zomato.svg',
                    alt: 'zomato',
                  },
                ]}
                imgUrl=""
                duration="20s"
              />
            </div>
          </div>
        </div>
        <Scrollspy
          leadContent={
            <div
              className="payout-nav-logo row row-no-margin justify-flex-start"
              style={{ minWidth: '190px' }}>
              <img
                src="/img/icons/payment-gateway.svg"
                alt="payment-gateway-icon"
                width="25px"
                loading="lazy"
              />
              <span>Payment Gateway</span>
            </div>
          }>
          <section
            data-spy-title="Features"
            data-spy-id="features"
            className="section-features pg-feature !pt-12 !pb-0 md:!py-32"
            ref={featuresRef}>
            <div className="container">
              <div
                className="row align-stretch mobile-wrap"
                ref={cardsContainerRef}>
                <div className="column">
                  <div className="payout-feature-heading cards-container ">
                    <p className="small-title with-square">
                      Grow your business
                    </p>
                    <h2 className="md:text-shl text-4md">
                      Best payment gateway <br className="hidden-mobile" />
                      built for India
                    </h2>

                    <div className="payout-feature-buttons">
                      <a
                        href="https://merchant.cashfree.com/merchants/signup?source-action=PG%20Page&action=Sign%20Up&button-id=StartNow_GrowYourBusiness"
                        className="button button-green w-full md:w-auto md:mb-0 md:mr-[16px] mt-0">
                        Create Account <span className="chevron" />
                      </a>
                      <a
                        href="https://www.cashfree.com/contact-sales/?source-action=PG%20Page&action=Contact%20Sales&button-id=ContactSales_GrowYourBusiness"
                        className="button button-outline-dark no-shadow w-full md:w-auto md:mb-0 md:mr-[16px] mt-4 md:mt-0">
                        Contact Sales <span className="chevron" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="column payout-features payout-feature-card">
                  {[
                    {
                      id: 0,
                      iconName: '/img/payment-gateways/payments-options.svg',
                      title: '120+ payment options',
                      content: (
                        <>
                          <p
                            className="md:text-md text-2.5sm text-cf-hero mb-6 md:mb-0"
                            style={{ maxWidth: '500px' }}>
                            Let your customers pay via any card, 65+ netbanking
                            options, UPI, Paytm &amp; other wallets, EMI and Pay
                            Later options.
                          </p>
                          <div className="md:block hidden">
                            <div className="row row-no-margin justify-flex-start flex-wrap feature-logo-block payment-modes">
                              {paymentModes.map(({ id, src, alt }) => (
                                <img
                                  src={`/img/payment-gateways/${src}`}
                                  loading="lazy"
                                  alt={alt}
                                  key={id}
                                  className="mr-[13px] mb-[13px] w-[63px] h-[63px]"
                                />
                              ))}
                            </div>
                          </div>
                          <LogoMarquee
                            data={paymentModes}
                            imgUrl="/img/payment-gateways"
                            itemClassName="mx-2 block md:hidden"
                          />
                        </>
                      ),
                    },

                    {
                      id: 1,
                      iconName: '/img/payment-gateways/fast-settlements.svg',
                      title: 'Faster payment gateway settlements',
                      content: (
                        <div style={{ maxWidth: '500px' }}>
                          <p className="md:text-md text-2.5sm text-cf-hero">
                            Accept payments and get fast settlements even on
                            bank holiday.
                          </p>
                          <div className="bg-cf-primary mt-5 tracking-normal px-4 pt-[20px] pb-[20px] text-[14px] leading-[24px] text-white rounded relative z-[1] border border-cf-stroke md:ml-0 ">
                            <p className="block text-[14px] leading-[24px]  mb-1 font-semibold">
                              Instant Settlements
                            </p>
                            <p>
                              Access funds within 15 minutes of payment capture.
                            </p>
                          </div>
                        </div>
                      ),
                    },
                    {
                      id: 2,
                      iconName: '/img/payment-gateways/go-global.svg',
                      title: 'Go global',
                      content: (
                        <div style={{ maxWidth: '500px' }}>
                          <p className="md:text-md text-2.5sm text-cf-hero">
                            Reach customers across the globe by unlocking
                            international payment options. Show items in 100+
                            foreign currencies. 2 days of settlements in INR.
                          </p>
                          <div className="bg-cf-primary mt-5 tracking-normal px-4 pt-[20px] pb-[20px] text-[14px] leading-[24px] text-white rounded relative z-[1] border border-cf-stroke  md:ml-0">
                            <p className="block text-[15px] leading-[24px]  mb-1 font-semibold italic">
                              <span className="text-4md font-black mr-2">
                                PayPal
                              </span>{' '}
                              Express Checkout
                            </p>
                          </div>
                        </div>
                      ),
                    },
                  ].map(({ id, iconName, title, content }) => (
                    <div
                      key={id}
                      className="item !max-w-xl md:!mb-[100px] !mb-12">
                      <div className="md:block flex">
                        <img
                          className="mr-[10px]"
                          src={iconName}
                          alt={title}
                          loading="lazy"
                        />
                        <h3 className="bold text-3md mt-4 mb-2 text-cf-hero">
                          {title}
                        </h3>
                      </div>
                      {content}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>

          <div className="p-section-2 bg-cf-light-grey">
            <div className="container">
              <div className="text-shs md:text-shl font-semibold max-w-[620px] mb-[24px] md:mb-[32px] font-heading">
                Innovate solutions to accelerate your online sales
              </div>
              <Tabs data={superChargeTabData} dropDown timerAutoPlay />
            </div>
          </div>

          <div className="content-image-block">
            <section className="section-padding md:!py-32 !py-12">
              <div className="container">
                <p className="small-title with-square">For enterprises</p>
                <h2
                  className="md:text-2lg text-4md"
                  style={{ maxWidth: '640px', marginBottom: '20px' }}>
                  Here is why growing businesses trust Cashfree Payment Gateway
                </h2>
                <Tabs
                  data={businessPaymentLinks}
                  dropDown
                  timerAutoPlay
                  dropDownFontSize="text-base"
                  customClass=""
                />
              </div>
            </section>
          </div>
          <div
            className="content-image-block"
            style={{ background: '#F6F6FE' }}>
            <section>
              <div className="container md:py-24 !py-12">
                <div className="row mobile-wrap">
                  <div className="column">
                    <div
                      className="content-block  !pt-0"
                      style={{ maxWidth: '485px' }}>
                      <h2 className="md:!text-4md !text-3md">
                        Centralised control with advanced dashboard
                      </h2>
                      <ul className="check-lit">
                        {centralisedControlList.map(({ id, listItem }) => (
                          <div className="flex" key={id}>
                            <span className="w-5 h-5 flex justify-center items-center rounded-full mr-2 bg-cf-primary flex-none mt-[2px]">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="9.21px"
                                height="6.29px"
                                viewBox="0 0 184 140"
                                fill="#fff">
                                <path
                                  fillRule="nonzero"
                                  d="M158.291 4.686c5.628-5.987 15.042-6.275 21.025-.644 5.386 5.068 6.158 13.203 2.156 19.144l-1.512 1.892L76.33 135.314c-4.855 5.164-12.573 6.117-18.474 2.575l-1.894-1.347L5.342 94.26c-6.306-5.267-7.15-14.652-1.886-20.961 4.786-5.736 12.974-6.956 19.16-3.173l1.791 1.286 39.854 33.277L158.29 4.686z"
                                />
                              </svg>
                            </span>
                            <li className="text-2.5sm md:text-md" key={id}>
                              {listItem}
                            </li>
                          </div>
                        ))}
                      </ul>

                      <a
                        id="StartNow_ControlwithDashboard"
                        href="https://merchant.cashfree.com/merchants/signup?source-action=PG%20Page&action=Sign%20Up&button-id=StartNow_ControlwithDashboard"
                        className="button button-green w-full md:w-auto md:mb-0 md:mr-[16px] my-8">
                        Create Account <span className="chevron" />
                      </a>
                    </div>
                  </div>
                  <div className="column">
                    <img
                      src="/img/payment-gateways/timeline-transaction.png"
                      alt="timeline"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </section>
          </div>

          <section
            className="section-developers cashgram-bg"
            data-spy-title="Integrations"
            data-spy-id="integrations">
            <div className="integrations relative md:!py-24 !py-12">
              <div className="container">
                <p
                  className="small-title with-square"
                  style={{ color: '#fff' }}>
                  Powerful integrations
                </p>
                <h2
                  className="white md:text-2lg text-4md"
                  style={{ maxWidth: '580px', marginBottom: '15px' }}>
                  Simplest and easiest payment gateway integrations
                </h2>
                <h3
                  className="small-title"
                  style={{ color: '#fff', margin: '50px 0 0' }}>
                  Web Integrations
                </h3>
                <div className="row row-no-margin justify-flex-start  flex-wrap integrations-block">
                  {[
                    {
                      id: 0,
                      url: 'https://docs.cashfree.com/docs/api-integration',
                      integrationsName: 'RESTful APIs',
                    },
                    {
                      id: 1,
                      url:
                        'https://docs.cashfree.com/docs/javascript-integration',
                      integrationsName: 'Javascript SDK',
                    },
                  ].map(({ id, integrationsName, url }) => (
                    <a
                      href={url}
                      key={id}
                      className="single-integration"
                      style={{ height: '55px' }}>
                      <span>{integrationsName}</span>
                    </a>
                  ))}
                </div>
                <h3
                  className="small-title"
                  style={{ color: '#fff', margin: '50px 0 0' }}>
                  Languages supported
                </h3>
                <div className="row row-no-margin justify-flex-start flex-wrap integrations-block">
                  {[
                    {
                      id: 0,
                      url: '#!',
                      integrationsName: 'PHP',
                    },
                    {
                      id: 1,
                      url: '#!',
                      integrationsName: 'Java',
                    },
                    {
                      id: 2,
                      url: '#!',
                      integrationsName: 'Go',
                    },
                    {
                      id: 3,
                      url: '#!',
                      integrationsName: 'Python',
                    },
                    {
                      id: 4,
                      url: '#!',
                      integrationsName: 'C#',
                    },
                  ].map(({ id, integrationsName, url }) => (
                    <a
                      href={url}
                      key={id}
                      className="single-integration"
                      style={{
                        height: '55px',
                        pointerEvents: 'none',
                        backgroundColor: 'inherit',
                      }}>
                      <span>{integrationsName}</span>
                    </a>
                  ))}
                </div>
                <h3
                  className="small-title"
                  style={{ color: '#fff', margin: '50px 0 0' }}>
                  Mobile Integrations
                </h3>
                <div className="row row-no-margin justify-flex-start flex-wrap integrations-block">
                  {[
                    {
                      id: 0,
                      url: 'https://docs.cashfree.com/docs/android-sdk',
                      imgPath: '/img/integrations/android-sdk.png',
                      integrationsName: 'Android SDK',
                    },
                    {
                      id: 1,
                      url: 'https://docs.cashfree.com/docs/ios-sdk ',
                      imgPath: '/img/integrations/ios-sdk.png',
                      integrationsName: 'iOS SDK',
                    },
                    {
                      id: 2,
                      url:
                        'https://docs.cashfree.com/docs/react-native-version-2-1-sdk ',
                      imgPath: '/img/payment-gateways/react-sdk.svg',
                      integrationsName: 'React Native SDK',
                    },
                    {
                      id: 3,
                      url: 'https://docs.cashfree.com/docs/flutter-sdk ',
                      imgPath: '/img/integrations/flutter-sdk.png',
                      integrationsName: 'Flutter SDK',
                    },
                    {
                      id: 4,
                      url: 'https://docs.cashfree.com/docs/cordova-sdk ',
                      imgPath: '/img/integrations/cordova-sdk.svg',
                      integrationsName: 'Cordova SDK',
                    },
                    {
                      id: 5,
                      url:
                        'https://docs.cashfree.com/docs/xamarin-android-support-sdk',
                      imgPath: '/img/integrations/xamrian-sdk.png',
                      integrationsName: 'Xamarin SDK',
                    },
                    {
                      id: 6,
                      url: 'https://docs.cashfree.com/docs/capacitor-sdk ',
                      imgPath: '/img/integrations/capacitor.png',
                      integrationsName: 'Capacitor SDK',
                    },
                  ].map(({ id, imgPath, integrationsName, url }) => (
                    <a href={url} key={id} className="single-integration">
                      <div className="integration-image">
                        <img
                          src={imgPath}
                          alt={integrationsName}
                          loading="lazy"
                        />
                      </div>
                      <span>{integrationsName}</span>
                    </a>
                  ))}
                </div>
                <h3
                  className="small-title"
                  style={{ color: '#fff', margin: '50px 0 0' }}>
                  Plugins
                </h3>
                <div className="row row-no-margin justify-flex-start flex-wrap integrations-block">
                  {integrations.map(
                    ({ id, imgPath, integrationsName, url }) => (
                      <a href={url} key={id} className="single-integration">
                        <div className="integration-image">
                          <img
                            src={imgPath}
                            alt={integrationsName}
                            loading="lazy"
                          />
                        </div>
                        <span>{integrationsName}</span>
                      </a>
                    ),
                  )}
                </div>
                <p
                  className="small-title text-2.5sm md:text-md"
                  style={{
                    color: '#a7a2e0',
                    margin: '40px 0 30px',
                    maxWidth: '355px',
                    fontWeight: '400',
                  }}>
                  Optimized website and mobile integrations to give your
                  customers the desired payment flow.
                </p>
                <a
                  href="https://docs.cashfree.com/docs/payment-gateway"
                  className="button button-green w-full md:w-auto md:mb-0 md:mr-[16px]">
                  View Developer Documentation <span className="chevron" />
                </a>
                <div className="row row-no-margin justify-flex-start cashgram-link mobile-wrap md:flex-row flex-col-reverse !pt-12 md:!pt-[96px]">
                  <div className="mt-8 md:mt-0" style={{ maxWidth: '325px' }}>
                    <img
                      src="/img/payment-gateways/cashgram.png"
                      alt="cashgram"
                      width={325}
                      loading="lazy"
                    />
                  </div>
                  <div
                    className="cashgram-link-content !mt-0 md:!mt-10"
                    style={{ maxWidth: '464px' }}>
                    <h3 className="white">No-code integration</h3>
                    <p
                      className="text-2.5sm md:text-md pr-[6px]"
                      style={{
                        color: '#a7a2e0',
                        marginBottom: '24px',
                      }}>
                      Use no-code payment links to collect payments over
                      WhatsApp, SMS, Facebook, Twitter and other channels
                      without writing any code.
                    </p>
                    <div
                      style={{
                        background: 'rgba(255, 255, 255, 0.0485)',
                        border: '1px solid rgba(220, 222, 230, 0.13)',
                      }}
                      className=" tracking-normal px-4 pt-[33px] pb-[16px] text-[14px] border-none leading-[24px] text-white relative z-[1] mt-[10px] border border-cf-stroke ml-[4px] md:ml-0 rounded">
                      <div className="ribbon-purple primary before:h-[10px]">
                        More
                      </div>
                      <p className="mt-3">
                        In case of cart abandonment or failed transactions send
                        payment links instantly to collect payments.
                      </p>
                    </div>
                    <a
                      href="https://www.cashfree.com/payment-links/"
                      className="button button-green w-full md:w-auto md:mb-0 md:mr-[16px]"
                      style={{ marginTop: '32px' }}
                      target="_blank"
                      rel="noopener noreferrer">
                      Explore Payment Links
                      <span className="chevron" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="content-image-block section-padding md:py-[120px] py-12">
            <div className="container">
              <div className="row flex-wrap flex-col lg:flex-row lg:items-center md:items-start items-center ">
                <div className="column">
                  <div className="lg:max-w-[410px]">
                    <h2 className="md:text-2lg text-4md lg:mb-[30px] mb-3">
                      Best UPI Payment Gateway in India
                    </h2>
                    <p>
                      With Cashfree Payments, offer your customers the best UPI
                      checkout experience, with the highest success rate.
                    </p>
                  </div>
                  <div className="mt-5 wakeFitContainer">
                    <div className="wakeFitInnerContainer">
                      <img
                        loading="lazy"
                        src="/img/payment-gateways/wakefit.svg"
                        alt="Wakefit"
                        width="77"
                      />
                      <p className="mt-2 wakeFitContainerParagraph">
                        “We saw that the UPI success percentages with other
                        payment aggregators were a little low. With Cashfree’s
                        Payment Gateway, we experienced a tremendous increase in
                        our success rates in UPI. This has helped us increase
                        our customer conversion ratio.” <br />{' '}
                        <span className="font-bold">Parasar Sarma</span> , Vice
                        President of Growth,{' '}
                        <span className="font-bold">Wakefit</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="best-payment-gateway-anim">
                    {bestPaymentGateway.map(({ id, content }) => (
                      <div
                        key={id}
                        className={`row row-no-margin justify-flex-start anim-wrapper${
                          id === animBestUpiPayment ? ' show' : ''
                        }`}>
                        <div className="row row-no-margin justify-flex-start align-baseline single-anim items-center">
                          <span>
                            <Icon name="tick" fill="#fff" />
                          </span>
                          <p className="md:text-md text-[9.5px]">{content}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="upi-payments ">
            <div className="tab-content-block md:!py-24 !py-12">
              <div className="container position-relative">
                <h2 className="md:text-2lg text-4md max-w-xl mb-5 font-heading">
                  Choose the right UPI payment gateway integration
                </h2>
                <Tabs
                  data={upiPayments}
                  dropDown
                  timerAutoPlay
                  dropDownFontSize="text-base"
                  customClass=""
                />
              </div>
            </div>
          </div>
          <section className="section-padding md:!py-32 !py-12">
            <div className="container">
              <div className="text-center flex justify-center items-center flex-col">
                <p className="small-title with-square inline-block">
                  Product features
                </p>
                <h2 className="md:text-2lg text-4md mb-[60px] max-w-[550px]">
                  Advanced payment gateway features available for all
                </h2>
              </div>
              <div className="row flex flex-wrap justify-flex-start align-flex-start items-stretch">
                <div className="single-pay-block mb-4">
                  <div className="img-text-block payment-card">
                    <img
                      src="/img/payment-gateways/instant-refunds.svg"
                      alt="instant-refunds"
                      loading="lazy"
                    />
                    <h3 className="body-font ">Instant refunds</h3>
                    <p className="md:text-md text-2.5sm ">
                      Do instant customer refunds - online orders, COD orders
                      and deposits with our advanced refund solutions and
                      delight your customers.
                    </p>
                  </div>
                </div>
                <div className="single-pay-block mb-4">
                  <div className="img-text-block payment-card">
                    <img
                      src="/img/payment-gateways/instant-settlement.svg"
                      alt="instant-settlements"
                      loading="lazy"
                    />
                    <h3 className="body-font ">Instant settlements</h3>
                    <p className="md:text-md text-2.5sm ">
                      Get access to your online payment gateway collections
                      within 15 minutes even on bank holidays.
                    </p>
                  </div>
                </div>
                <div className="single-pay-block mb-4">
                  <div className="img-text-block payment-card">
                    <img
                      src="/img/payment-gateways/pre-auth.svg"
                      alt="card-pre-authorization"
                      loading="lazy"
                    />
                    <h3 className="body-font ">Pre-authorization</h3>
                    <p className="md:text-md text-2.5sm ">
                      Temporarily block some amount of funds when a customer
                      places an order and save payment gateway charges on
                      canceled orders with Pre-auth.
                    </p>
                  </div>
                </div>
                <div className="single-pay-block mb-4">
                  <div className="img-text-block payment-card">
                    <img
                      src="/img/payment-gateways/saved-cards.svg"
                      alt="saved-cards"
                      loading="lazy"
                    />
                    <h3 className="body-font ">Token Vault</h3>
                    <p className="md:text-md text-2.5sm ">
                      Process saved card payments securely while being RBI
                      compliant. Token Vault is India&apos;s first interoperable
                      card tokenization solution that works across all card
                      networks and payment gateways.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            className="md:py-24 py-12 relative overflow-hidden"
            style={{ background: '#F6F6FE' }}>
            <div className="container">
              <p className="small-title with-square">
                Use cases for various industries{' '}
              </p>
              <h2
                className="md:text-2lg text-4md"
                style={{ maxWidth: '730px' }}>
                Payment Gateway for innovative businesses
              </h2>
              <p className="max-w-2xl md:text-md text-2.5sm mt-3">
                Innovative businesses are using advanced payment gateway
                features to scale their business while delighting customers.
              </p>
              <div className="flex  mt-10 pg-slider">
                <div className="w-full ">
                  <BusinessSlider
                    data={useCases}
                    settings={{
                      dots: true,
                      arrows: false,
                      infinite: false,
                      speed: 500,
                      slidesToShow: 3,
                      responsive: [
                        {
                          breakpoint: 768,
                          settings: {
                            // centerMode: false,
                            slidesToShow: 1.3,
                            infinite: false,
                            arrows: true,
                            dots: true,
                          },
                        },
                      ],
                    }}
                    sliderContainer="!bg-white"
                    sliderFocusContainer="!bg-cf-light-grey"
                  />
                </div>
              </div>
            </div>
          </section>
          <div
            data-spy-title="Pricing"
            data-spy-id="pricing"
            className="section-padding pricing-main md:!py-[100px] !py-[48px]">
            <div className="container">
              <div className="row mobile-wrap">
                <div className="pg-price-col">
                  <div className="pg-price-text">
                    <p className="small-title with-square">Lowest Pricing</p>
                    <h2 className="md:!text-2lg !text-4md">
                      Lowest payment gateway charges in India
                    </h2>

                    <p
                      className=" md:text-md text-2.5sm"
                      style={{ maxWidth: 540, margin: '15px 0 30px' }}>
                      Accept online payments in Indian Rupees (INR) and other
                      100+ foreign currencies with minimal integration effort
                      and go live in no time.
                    </p>
                    <ul
                      className="check-list-round two-col-check pr-8 md:pr-0"
                      style={{ maxWidth: '100%' }}>
                      {lowPricingChecklist.map(({ id, listItem }) => (
                        <li key={id}>
                          <span className="w-5 h-5 flex justify-center items-center rounded-full mr-2 !bg-cf-primary flex-none mt-[2px]">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="9.21px"
                              height="6.29px"
                              viewBox="0 0 184 140"
                              fill="#fff">
                              <path
                                fillRule="nonzero"
                                d="M158.291 4.686c5.628-5.987 15.042-6.275 21.025-.644 5.386 5.068 6.158 13.203 2.156 19.144l-1.512 1.892L76.33 135.314c-4.855 5.164-12.573 6.117-18.474 2.575l-1.894-1.347L5.342 94.26c-6.306-5.267-7.15-14.652-1.886-20.961 4.786-5.736 12.974-6.956 19.16-3.173l1.791 1.286 39.854 33.277L158.29 4.686z"
                              />
                            </svg>
                          </span>
                          <p className="!text-cf-hero">{listItem}</p>
                        </li>
                      ))}
                    </ul>

                    <a
                      href="https://www.cashfree.com/payment-gateway-charges/"
                      className="button button-green w-full md:w-auto">
                      Check Pricing <span className="chevron" />
                    </a>
                  </div>
                </div>
                <div className="pg-price-box-col">
                  <div className="pg-price-box md:!py-10 md:!px-[35px] !py-6 !px-6">
                    <img
                      src="/img/icons/fast-settlements-purple.svg"
                      loading="lazy"
                      alt="fast-settlements"
                    />
                    <p>
                      <span>Faster settlements</span>
                    </p>
                    <p className="md:text-md text-2.5sm">
                      Once your account is activated, you can monitor the status
                      of all transactions and settlements. We also offer 15
                      minutes payment gateway settlements on request.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Scrollspy>
        <div className="mobile-app-block md:!pt-[30px] ">
          <div className="container">
            <div className="row tablet-wrap">
              <div className="column">
                <div className="content">
                  <h2
                    className="text-2lg"
                    style={{ fontFamily: 'Termina Demi' }}>
                    Now available on Google Play Store
                  </h2>
                  <p className=" md:text-md text-2.5sm">
                    Download Cashfree merchant mobile app and access your
                    payment gateway account from anywhere you go.
                  </p>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.cashfree.merchant"
                    target="_blank"
                    rel="noopener noreferrer">
                    <img
                      src="/img/play-store.svg"
                      style={{ width: '150px' }}
                      alt="playstore"
                      loading="lazy"
                    />
                  </a>
                </div>
              </div>
              <div
                className="column pt-[50px] md:pt-[70px]"
                style={{ maxWidth: '628px' }}>
                <img
                  src="/img/payment-gateways/transaction-details.png"
                  alt=""
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container general-faqs">
          <FAQ
            supportLinkHref="https://www.cashfree.com/help/hc/"
            data={faqData}
          />
        </div>

        <div className="other-products section-padding md:py-[100px] py-[48px]">
          <div className="container">
            <h2
              className="md:text-2lg text-4md"
              style={{ marginBottom: '40px' }}>
              Explore other Cashfree Payments products
            </h2>
            <div className="row flex-wrap mobile-wrap">
              {otherProducts.map(({ id, title, body, iconname, url }) => (
                <div className="other-product-col" key={id}>
                  <div
                    className="single-other-product"
                    style={{ padding: '24px 24px 57px' }}>
                    <img src={iconname} alt={title} loading="lazy" />
                    <div className="other-products-text">
                      <h3>{title}</h3>
                      <p className="text-2.5sm md:text-md">{body}</p>
                      <a
                        className=" btn-green "
                        style={{ color: '#05c16e' }}
                        href={url}>
                        Learn more <span className="chevron" />
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <GetStarted
          startNowLink="https://merchant.cashfree.com/merchants/signup?source-action=PG%20Page&action=Sign%20Up&button-id=StartNow_Footer"
          getInTouchLink="https://www.cashfree.com/contact-sales/?source-action=PG%20Page&action=Contact%20Sales&button-id=ContactSales_Footer"
        />
      </section>
    </Layout>
  );
};

PaymentGatewayPage.defaultProps = {
  lottie: null,
};

PaymentGatewayPage.propTypes = {
  lottie: PropTypes.shape({}),
};

// export default PaymentGatewayPage;

export default makeAsyncScriptLoader('/lottie/lottie_light.min.js', {
  globalName: 'lottie',
})(PaymentGatewayPage);
