import React from 'react';
// import Icon from "../components/Icon";
import TabPanel from '../components/Tabs/TabPanel';
// import PaymentGatewaySlider from "../components/Sliders/PaymentGatewaySlider/PaymentGatewaySlider";

// const alignStart = true;
const contentClassName = 'md:pl-[19px] mt-0 ';
const headingClass = 'mb-2 md:mb-[12px] hidden md:block';
const imageClassName = 'w-full md:pr-[18px]';

const headingClass1 = 'mb-2 md:mb-[12px]';
const contentBodyMarginClass = 'mb-1 md:mb-8 max-w-[500px]';
const imageClassName1 = 'w-[93%] md:pr-[18px]';

const mobileVisibleHeading = false;
const Icons = [
  'https://cashfreelogo.cashfree.com/website/landings/payment-gateway/zestPG.png',
  'https://cashfreelogo.cashfree.com/website/landings/payment-gateway/simplPG.png',
  'https://cashfreelogo.cashfree.com/website/landings/payment-gateway/kreditbeePG.png',
  'https://cashfreelogo.cashfree.com/website/landings/payment-gateway/hdfcPG.png',
  0,
];

const IconsSmartSell = [
  'https://cashfreelogo.cashfree.com/website/landings/affordability/icons/shopify.png',
  'https://cashfreelogo.cashfree.com/website/landings/affordability/icons/woocommerce.png',
];
export const superChargeTabData = [
  {
    key: 0,
    heading: 'BNPL Suite',
    shouldUnmount: true,
    content: (
      <div className="md:mt-[60px]">
        <TabPanel
          img={{
            src:
              'https://cashfreelogo.cashfree.com/website/landings/shopify-payment-gateway-india/Shopify1.svg',
            width: '610',
            height: '512',
          }}
          heading="BNPL Suite"
          alignStart={false}
          contentClassName={contentClassName}
          imageClassName={imageClassName}
          headingClass={headingClass}
          body={
            <>
              <div className="max-w-[550px] text-[15px] font-normal">
                Cashfree offers India’s widest Buy Now Pay Later Suite. Provide
                flexible payment options from over 30+ partners - including
                card-based, cardless EMI and Pay Later partners - to give a 40%
                boost to average order value.{' '}
              </div>

              <span className="body-text font-semibold text-[15px] inline-block mt-[30px]">
                30+ leading banks & partners:
              </span>
              <div className="flex gap-[24px] mt-[18px] flex-wrap ">
                {Icons.map((data) => (
                  <div className="flex">
                    {' '}
                    {data !== 0 ? (
                      <img
                        alt="Icons-payment"
                        src={data}
                        className="w-[64px] h-[64px]"
                        loading="lazy"
                      />
                    ) : (
                      <div className="w-[64px] flex items-center justify-center text-[12px] leading-[12px] h-[64px] font-medium rounded-full bg-[#230152] text-cf-white border-[#E2E3EB]">
                        Many
                        <br />
                        More
                      </div>
                      // <span className="body-text font-semibold text-[10px] text-center flex justify-center items-center">
                      //   & many more
                      // </span>
                    )}{' '}
                  </div>
                ))}
              </div>
              {/* <PaymentGatewaySlider
                data={logoData}
                className="w-full max-w-[400px] relative shopify"
                settings={{
                  arrows: false,
                  dots: false,
                  variableWidth: true,
                  autoplay: true,
                  infinite: true,
                  centerMode: false,
                  autoplaySpeed: 0,
                  speed: 2000,
                  cssEase: "linear",
                  pauseOnHover: false,
                }}
              /> */}
            </>
          }
          footer={
            <a
              className="button button-green w-full md:w-auto px-[24.5px] mt-[32px] md:mt-[30px] "
              href="https://www.cashfree.com/buy-now-pay-later/">
              Learn More <span className="chevron" />
            </a>
          }
        />
      </div>
    ),
  },
  {
    key: 1,
    heading: 'Offer Engine',
    content: (
      <div className="md:mt-[60px]">
        <TabPanel
          heading="Offer Engine"
          headingClass={headingClass}
          img={{
            src:
              'https://cashfreelogo.cashfree.com/website/landings/shopify-payment-gateway-india/Shopify2.svg',
            width: '610',
            height: '512',
          }}
          imageClassName={imageClassName}
          alignStart={false}
          contentClassName={contentClassName}
          body={
            <>
              <div className="max-w-[510px] text-[15px] font-normal">
                Use the no-code Offer Engine to configure special offers on your
                checkout without any effort. Delight your customers discounts
                and cashbacks and increase conversions by 30%.
              </div>
            </>
          }
        />
      </div>
    ),
  },
  {
    key: 2,
    heading: 'SmartSell',
    content: (
      <div className="md:mt-[60px]">
        <TabPanel
          heading="SmartSell"
          headingClass={headingClass}
          isVideo
          img={{
            src: '/img/payment-gateways/smart-sell.mp4',
            width: '610',
            height: '512',
          }}
          imageClassName={imageClassName}
          alignStart={false}
          contentClassName={contentClassName}
          body={
            <>
              <div className="max-w-[510px] text-[15px] font-normal">
                Sell like the best of brands by displaying offers and EMIs
                upfront on your product page. Reduce customer drop offs by upto
                30%, stimulate the intent to buy high value products and provide
                a delightful shopping experience.
              </div>
              <span className="body-text font-semibold text-[15px] inline-block mt-[30px]">
                Plug-in available for:
              </span>
              <div className="flex gap-[24px] mt-[18px]">
                {IconsSmartSell.map((data) => (
                  <img
                    alt="Icons-payment"
                    src={data}
                    className="w-[64px] h-[64px]"
                    loading="lazy"
                  />
                ))}
              </div>
            </>
          }
          footer={
            <div className="mt-[32px] md:mt-[30px]">
              <a
                className="button button-green md:px-[24.5px] w-full md:w-auto"
                href="https://cashfreepayments.typeform.com/to/zbRkc6Dt">
                Get Early Access <span className="chevron !mb-0" />
              </a>
            </div>
          }
        />
      </div>
    ),
  },
];

export const businessPaymentLinks = [
  {
    key: 0,
    heading: 'Scalable',
    content: (
      <div className="md:mt-[60px] pb-[12px] md:pb-0">
        <TabPanel
          headingType="div"
          headingClass={headingClass1}
          img={{
            src: '/img/payment-gateways/auto-reconcilation.png',
            alt: 'sell products',
            width: '610',
            height: '464',
          }}
          // heading="Serve customers better with auto&#x2212;reconciliation"
          heading="Built for very high scale"
          imageClassName={imageClassName1}
          // alignStart={alignStart}
          contentClassName="!flex !justify-center !flex-col !items-baseline"
          mobileVisibleHeading
          body={
            <>
              <div className={contentBodyMarginClass}>
                Cashfree Payment Gateway offers reliability and scales with you.
                Process 3,000 transactions per second smoothly without any
                downtimes.
              </div>
            </>
          }
          footer={
            <a
              className="button button-green mt-8 md:mt-0 mr-4 wide w-full md:w-auto"
              href="https://www.cashfree.com/contact-sales/?source-action=PG%20Page&action=Contact%20Sales&button-id=ContactSales_Scalable">
              Contact Sales <span className="chevron" />
            </a>
          }
        />
      </div>
    ),
  },
  {
    key: 1,
    heading: 'Success Rate',

    content: (
      <div className="md:mt-[60px] pb-[12px] md:pb-0">
        <TabPanel
          headingType="div"
          headingClass={headingClass1}
          img={{
            src: '/img/payment-gateways/success-rate.png',
            alt: 'bill payments',
            width: '610',
            height: '464',
          }}
          heading="15% higher success rate"
          imageClassName={imageClassName1}
          // alignStart={alignStart}
          contentClassName="!flex !justify-center !flex-col !items-baseline"
          mobileVisibleHeading
          body={
            <>
              <div className={contentBodyMarginClass}>
                <p className="mb-4">
                  With direct integrations and smart 3 times auto-retry option,
                  saved card option, smart routing among multiple banks and
                  networks, and optimized integration flows for mobile and web,
                  achieve higher success rates every time.
                </p>

                <p>
                  With dynamic routing across multiple acquiring banks and saved
                  payment instrument details we optimize payment conversion
                  rates by as much as 15%.
                </p>
              </div>
            </>
          }
          footer={
            <a
              className="button button-green mt-8 md:mt-0 mr-4 wide w-full md:w-auto"
              href="https://www.cashfree.com/contact-sales/?source-action=PG%20Page&action=Contact%20Sales&button-id=ContactSales_SuccessRate">
              Contact Sales <span className="chevron" />
            </a>
          }
        />
      </div>
    ),
  },
  {
    key: 2,
    heading: 'Auto Reconciliation',

    content: (
      <div className="md:mt-[60px] pb-[12px] md:pb-0">
        <TabPanel
          headingType="div"
          headingClass={headingClass1}
          img={{
            src: '/img/payment-gateways/auto-reconcile.png',
            alt: 'Loan Repayments',
            width: '610',
            height: '464',
          }}
          heading="Serve customers better with auto&#x2212;reconciliation"
          imageClassName={imageClassName1}
          // alignStart={alignStart}
          contentClassName="!flex !justify-center !flex-col !items-baseline"
          mobileVisibleHeading
          body={
            <>
              <div className={contentBodyMarginClass}>
                Define completion time for time-sensitive transactions between 5
                minutes to 24 hours and mark status as success or failed and
                save customers from payment uncertainties.
              </div>
            </>
          }
          footer={
            <>
              <a
                className="button button-green mt-8 md:mt-0 mr-4 wide w-full md:w-auto"
                href="https://www.cashfree.com/contact-sales/?source-action=PG%20Page&action=Contact%20Sales&button-id=ContactSales_AutoReconciliation">
                Contact Sales <span className="chevron" />
              </a>
            </>
          }
        />
      </div>
    ),
  },
  {
    key: 3,
    heading: 'API and Webhook',

    content: (
      <div className="md:mt-[60px] pb-[12px] md:pb-0">
        <TabPanel
          headingType="API and Webhook"
          headingClass={headingClass1}
          img={{
            src: '/img/payment-gateways/api-webhook.png',
            alt: 'Fee Payments',
            width: '610',
            height: '464',
          }}
          heading="API and webhook based reconciliation"
          imageClassName={imageClassName1}
          // alignStart={alignStart}
          contentClassName="!flex !justify-center !flex-col !items-baseline"
          mobileVisibleHeading
          body={
            <>
              <div className={contentBodyMarginClass}>
                Reconcile all payments, refunds and settlements using powerful
                APIs. Get notified on transaction status - success, pending, etc
                in real-time using webhooks.
              </div>
            </>
          }
          footer={
            <a
              className="button button-green mt-8 md:mt-0 mr-4 wide w-full md:w-auto"
              href="https://www.cashfree.com/contact-sales/?source-action=PG%20Page&action=Contact%20Sales&button-id=ContactSales_APIWebhook">
              Contact Sales <span className="chevron" />
            </a>
          }
        />
      </div>
    ),
  },
];
export const upiPayments = [
  {
    key: 0,
    heading: 'UPI Intent flow',
    content: (
      <div className="md:mt-[60px] pb-[12px] md:pb-0">
        <TabPanel
          headingType="div"
          headingClass={headingClass1}
          isVideo
          img={{
            src: '/img/payment-gateways/upioptions-1.mp4',
            alt: 'sell products',
            width: '610',
            height: '464',
          }}
          heading="UPI Intent flow"
          imageClassName={imageClassName1}
          // alignStart={alignStart}
          contentClassName="!flex !justify-center !flex-col !items-baseline"
          mobileVisibleHeading={mobileVisibleHeading}
          body={
            <>
              <div className={contentBodyMarginClass}>
                Automatically launch UPI apps installed in customer&apos;s
                mobile for faster payments. Recommended for all mobile payments
                (web and in-app).
              </div>
            </>
          }
          footer={
            <a
              className="button button-green mt-8 md:mt-0 mr-4 wide w-full md:w-auto"
              href="https://merchant.cashfree.com/merchants/signup?source-action=PG%20Page&action=Sign%20Up&button-id=StartNow_UPIIntentFlow">
              Create Account <span className="chevron" />
            </a>
          }
        />
      </div>
    ),
  },
  {
    key: 1,
    heading: 'Desktop QR',

    content: (
      <div className="md:mt-[60px] pb-[12px] md:pb-0">
        <TabPanel
          headingType="div"
          headingClass={headingClass1}
          isVideo
          img={{
            src: '/img/payment-gateways/upioptions-2.mp4',
            alt: 'bill payments',
            width: '610',
            height: '464',
          }}
          heading="Desktop QR"
          imageClassName={imageClassName1}
          // alignStart={alignStart}
          contentClassName="!flex !justify-center !flex-col !items-baseline"
          mobileVisibleHeading={mobileVisibleHeading}
          body={
            <>
              <div className={contentBodyMarginClass}>
                <p>
                  Generate QR code on your checkout page and let customers scan
                  and pay using any UPI app.
                </p>
              </div>
            </>
          }
          footer={
            <a
              className="button button-green mt-8 md:mt-0 mr-4 wide w-full md:w-auto"
              href="https://merchant.cashfree.com/merchants/signup?source-action=PG%20Page&action=Sign%20Up&button-id=StartNow_DesktopQR">
              Create Account <span className="chevron" />
            </a>
          }
        />
      </div>
    ),
  },
  {
    key: 2,
    heading: 'WhatsApp Links',

    content: (
      <div className="md:mt-[60px] pb-[12px] md:pb-0">
        <TabPanel
          headingType="div"
          headingClass={headingClass1}
          isVideo
          img={{
            src: '/img/payment-gateways/upioptions-3.mp4',
            alt: 'Loan Repayments',
            width: '610',
            height: '464',
          }}
          heading="WhatsApp Links"
          imageClassName={imageClassName1}
          // alignStart={alignStart}
          contentClassName="!flex !justify-center !flex-col !items-baseline"
          mobileVisibleHeading={mobileVisibleHeading}
          body={
            <>
              <div className={contentBodyMarginClass}>
                Generate UPI link to collect payment and share with customers
                over WhatsApp.
              </div>
            </>
          }
          footer={
            <>
              <a
                className="button button-green mt-8 md:mt-0 mr-4 wide w-full md:w-auto"
                href="https://merchant.cashfree.com/merchants/signup?source-action=PG%20Page&action=Sign%20Up&button-id=StartNow_WhatsappLink">
                Create Account <span className="chevron" />
              </a>
            </>
          }
        />
      </div>
    ),
  },
  {
    key: 3,
    heading: 'Collect',

    content: (
      <div className="md:mt-[60px] pb-[12px] md:pb-0">
        <TabPanel
          headingType="API and Webhook"
          headingClass={headingClass1}
          isVideo
          img={{
            src: '/img/payment-gateways/upioptions-4.mp4',
            alt: 'Fee Payments',
            width: '610',
            height: '464',
          }}
          heading="Collect"
          imageClassName={imageClassName1}
          // alignStart={alignStart}
          contentClassName="!flex !justify-center !flex-col !items-baseline"
          mobileVisibleHeading={mobileVisibleHeading}
          body={
            <>
              <div className={contentBodyMarginClass}>
                Send a collect request to a particular user phone number or UPI
                ID.
              </div>
            </>
          }
          footer={
            <a
              className="button button-green mt-8 md:mt-0 mr-4 wide w-full md:w-auto"
              href="https://merchant.cashfree.com/merchants/signup?source-action=PG%20Page&action=Sign%20Up&button-id=StartNow_Collect">
              Create Account <span className="chevron" />
            </a>
          }
        />
      </div>
    ),
  },
];

export const paymentModes = [
  {
    id: 0,
    src: 'upi.svg',
    alt: 'upi',
  },
  {
    id: 1,
    src: 'phonepe.svg',
    alt: 'phonepe',
  },
  {
    id: 2,
    src: 'paytm.svg',
    alt: 'paytm',
  },
  {
    id: 3,
    src: 'visa.svg',
    alt: 'visa',
  },
  {
    id: 4,
    src: 'mastercard.svg',
    alt: 'mastercard',
  },
  {
    id: 5,
    src: 'rupay.svg',
    alt: 'rupay',
  },
  {
    id: 6,
    src: 'maestro.svg',
    alt: 'maestro',
  },
  {
    id: 7,
    src: 'amazon-pay.svg',
    alt: 'amazon-pay',
  },
  {
    id: 8,
    src: 'gpay.svg',
    alt: 'gpay',
  },
  {
    id: 9,
    src: 'ola_money.svg',
    alt: 'ola_money',
  },
  {
    id: 10,
    src: 'zest.svg',
    alt: 'zest',
  },
  {
    id: 11,
    src: 'paypal.svg',
    alt: 'paypal',
  },
  {
    id: 12,
    src: 'payment1.svg',
    alt: 'payment-icon',
  },
  {
    id: 13,
    src: 'payment2.svg',
    alt: 'payment-icon',
  },
];

export const centralisedControlList = [
  {
    id: 0,
    listItem:
      'Dive deep into the history of each transaction - order created, payment status, refund, etc',
  },
  {
    id: 1,
    listItem: 'Check settlements and adjustments in real-time',
  },
  {
    id: 2,
    listItem: 'Monitor and act on open disputes and suspicious transactions',
  },
  {
    id: 3,
    listItem:
      'Get detailed reports or create custom reports on payments, settlements, refunds and settlement reconciliation',
  },
];

export const faqData = [
  {
    id: 0,
    q: 'What is a payment gateway?',
    a: (
      <>
        <p>
          A payment gateway is an online platform that helps businesses accept
          payments from customers. A payment gateway consists of multiple
          components that interact with the merchant&apos;s website and
          customer&apos;s issuing bank, card associations, and online wallets to
          complete a transaction.
        </p>
      </>
    ),
  },
  {
    id: 1,
    q: 'What payment modes are supported on Cashfree Payments?',
    a: (
      <>
        <p>
          Cashfree Payments supports the widest range of payment modes with a
          single integration. We support over 120+ payment modes such as Credit
          and Debit Card (Visa, Mastercard, Rupay, AMEX, Diners), Net Banking of
          more than 65 banks, Paytm and other wallets, UPI via BHIM UPI, Google
          Pay, PhonePe, EMI options, buy now paylater and so on.
        </p>
        <p>
          We also provide multiple bank EMI and cardless EMI options such as
          Zest Money. With Payment Gateway you can also give your customers buy
          now pay later option by using Ola Money Postpaid, ePayLater, etc. Our
          merchants say that the buy now pay later option has increased the
          checkout success rate by 25-30% for high ticket value transactions.
          For details, visit the pricing page. You can find more about payment
          modes here:
          <br />
          <a
            href="https://docs.cashfree.com/docs/payment-methods"
            className="email-link">
            https://docs.cashfree.com/docs/payment-methods
          </a>
        </p>
      </>
    ),
  },
  {
    id: 2,
    q:
      'What are the platforms that payment gateway by Cashfree Payments supports?',
    a: (
      <>
        <p>
          You can integrate our payment gateway for website or mobile app to
          accept payments. Over and above this, we also offer plugins to major
          ecommerce sites like Shopify, Magento, Woo-commerce, OpenCart,
          PrestaShop, WHMCS. Know more here:
          <br />
          <a
            href="https://docs.cashfree.com/docs/plugins"
            className="email-link">
            https://docs.cashfree.com/docs/plugins
          </a>
        </p>
      </>
    ),
  },
  {
    id: 3,
    q: 'How can I set up payment gateway by Cashfree Payments for my website?',
    a: (
      <>
        <p>
          Cashfree Payments, as an online payment gateway, offers a wide range
          of web integrations. You can choose easy-to-integrate Cashfree
          Payments hosted checkout form or go for customised checkout experience
          integrations like Seamless Basic or Seamless Pro as per your business
          need. You can integrate our Payment Gateway for website in any of the
          languages - PHP, Laravel, Java, Python or C++
        </p>
      </>
    ),
  },
  {
    id: 4,
    q: 'How Can I set up payment gateway by Cashfree Payments for Mobile?',
    a: (
      <>
        <p>
          Cashfree Payments offers a wide range of mobile integrations. We
          support integration on Android, iOS, React Native, and Xamarin
          platforms. Refer to our developer documentation{' '}
          <a
            href="https://docs.cashfree.com/docs/mobile-integration"
            className="email-link">
            here
          </a>
          .
        </p>
      </>
    ),
  },
  {
    id: 5,
    q: 'Does Cashfree Payments support international payments?',
    a: (
      <>
        <p>
          Cashfree Payments domestic and international online payment gateway is
          built for growing businesses. We support 100+ payment modes both
          domestic and international to help you go global.
        </p>

        <p>
          For domestic payments: We support 65+ netbanking options, Credit Card,
          Debit Card, NEFT, IMPS, Paytm and other wallets, UPI via BHIM UPI ,
          Google Pay, PhonePe etc. We also provide multiple bank EMI and
          cardless EMI options such as Zest Money, along with buy now pay later
          option (Ola Money Postpaid, ePayLater etc.)
        </p>

        <p>
          For international customers: With support for over 30+ international
          currencies, show your products to your customers in their preferred
          currency. Accept international payments via popular cards including
          Visa, Mastercard, Amex, Diners Club, and PayPal.
        </p>

        <p>
          PayPal Express Checkout: Cashfree Payments offers direct integration
          with PayPal. Now connect your PayPal business account with Cashfree
          Payments and accept payments from over 350 mn PayPal users across 200
          markets worldwide using PayPal Express Checkout from day one.
        </p>

        <p>
          Know more here:
          <br />
          <a
            className="email-link"
            href="https://knowledgebase.cashfree.com/support/solutions/articles/48001176153-paypal-exclusive-offer-paypal-express-checkout-on-cashfree-payment-gateway-">
            https://knowledgebase.cashfree.com/support/solutions/articles/48001176153-paypal-exclusive-offer-paypal-express-checkout-on-cashfree-payment-gateway-
          </a>
        </p>
      </>
    ),
  },
  {
    id: 6,
    q: 'What are the payment gateway charges?',
    a: (
      <>
        <p>
          Simple and user-friendly: 1.90%. There is zero setup fee and we charge
          no annual maintenance fee. For a detailed breakdown of payment gateway
          charges by payment mode, visit{' '}
          <a
            className="email-link"
            href="https://www.cashfree.com/payment-gateway-charges/">
            our pricing page.
          </a>
        </p>
      </>
    ),
  },
  {
    id: 7,
    q: 'Can I sign up as an individual?',
    a: (
      <>
        <p>
          Yes, you can sign up as an individual for a payment gateway. Please
          share your personal address proof instead of the business registration
          details. Here is the{' '}
          <a
            className="email-link"
            href="https://knowledgebase.cashfree.com/support/solutions/articles/48001146022-what-are-the-documents-needed-for-activating-my-cashfree-account-">
            list of documents
          </a>{' '}
          required for integration.
        </p>
      </>
    ),
  },
  {
    id: 8,
    q: 'What features are provided by Payment Gateway by Cashfree Payments?',
    a: (
      <>
        <p>
          Cashfree Payments offers a wide range of payment gateway features such
          as instant refunds including COD order refunds, instant settlements,
          card preauthorization, etc. Cashfree Payments helps businesses with
          both domestic and international payment collection.
        </p>
      </>
    ),
  },
  {
    id: 9,
    q: 'In which languages do you have integration kits available?',
    a: (
      <>
        <p>
          We have payment gateway integration in PHP, Java, Python, NodeJS, Ruby
          and.Net.
        </p>
        <p>
          With our ready-to-use integration kits, you will be able to integrate
          the best payment gateway in no time. Throughout your onboarding
          process and account activation, you will have a dedicated manager who
          will assist you at every stage. Our Product Experts will also be
          available for any assistance you may need.
        </p>
      </>
    ),
  },
  {
    id: 10,
    q: 'Can I do payment gateway integration in Android & iOS apps?',
    a: (
      <>
        <p>
          Yes, You can use our library to integrate our Payment Gateway directly
          into your Android or iOS app using Cashfree Payments SDK. Cashfree
          Payments SDK has been designed to offload the complexity of handling
          and integrating payments in your app.
        </p>

        <p>
          Know more here:
          <br />
          <a
            className="email-link"
            href="https://docs.cashfree.com/docs/mobile-integration">
            https://docs.cashfree.com/docs/mobile-integration
          </a>
        </p>
      </>
    ),
  },
  {
    id: 11,
    q: 'What is the procedure for payment gateway integration?',
    a: (
      <>
        <p>
          We do 100% paperless onboarding for merchants on our Payment Gateway.
          Following are the steps for integration{' '}
        </p>

        <p>1. Signup on Cashfree Payments</p>

        <p>
          2. Update your business profile and upload scanned copies of business
          documents
        </p>

        <p>
          3. You can try out the payment gateway yourself. Login and switch to
          test account. Check integration documentation.
        </p>

        <p>
          4. Our Payment expert will call you in the next 24 hours, share your
          business requirements and we will help you pick the right set of
          features.
        </p>

        <p>
          5. Once the account is verified, our product team will help you with
          the integration.
        </p>
      </>
    ),
  },
  {
    id: 12,
    q:
      'What are the documents required for integrating payment gateway by Cashfree Payments?',
    a: (
      <>
        <p>
          Once you sign-up, to activate your payment gateway merchant account,
          you need to share business details along with scanned copies of the
          following business documents. Please refer to the list of documents{' '}
          <a
            className="email-link"
            href="https://knowledgebase.cashfree.com/support/solutions/articles/48001146022-what-are-the-documents-needed-for-activating-my-cashfree-account-">
            here
          </a>
          .
        </p>
      </>
    ),
  },
  {
    id: 13,
    q: 'How many days does the integration take?',
    a: (
      <>
        <p>
          Cashfree Payments is the easiest & best payment gateway solution for
          any developer. You can do the integration on any website with any
          stack. We have simple payment APIs with detailed documentation and
          SDKs for all major platforms. With responsive developer support,
          integrating Cashfree Payments is a smooth experience!
        </p>
      </>
    ),
  },
  {
    id: 14,
    q:
      'I want to add Google Pay and other UPI options on the checkout page. How to do that?',
    a: (
      <>
        <p>
          At Cashfree Payments, we provide the widest range of UPI integrations
          including Webflow, intent flow, Google Pay integration, and UPI SDK
          integration. You need to just share your requirement and our UPI
          payment experts will help you analyze the various UPI modes and
          recommend the best one as per your business requirement.
        </p>
      </>
    ),
  },
  {
    id: 15,
    q:
      'Are there any specific businesses the payment gateway services will not be available for?',
    a: (
      <>
        <p>
          As a payments company we strive to cater to all businesses, however,
          there are some services and goods, for which we don&apos;t provide our
          payment gateway services. Here is{' '}
          <a
            className="email-link"
            href="https://knowledgebase.cashfree.com/support/solutions/articles/48001146025-are-there-any-specific-businesses-that-are-not-supported-by-cashfree-">
            the list
          </a>{' '}
          of restricted businesses.
        </p>
      </>
    ),
  },
  {
    id: 16,
    q: 'Are NRO and NRE accounts supported?',
    a: (
      <>
        <p>
          NRO accounts are supported for merchants signing up as an individual.
          NRE is not supported.
        </p>
      </>
    ),
  },
];

export const integrations = [
  {
    id: 0,
    url: 'https://docs.cashfree.com/docs/shopify',
    imgPath: '/img/integrations/shopify.png',
    integrationsName: 'Shopify',
  },
  {
    id: 1,
    url: 'https://docs.cashfree.com/docs/woocommerce',
    imgPath: '/img/integrations/woocommerce.png',
    integrationsName: 'WooCommerce',
  },
  {
    id: 2,
    url: 'https://docs.cashfree.com/docs/magento',
    imgPath: '/img/payment-gateways/magento.svg',
    integrationsName: 'Magento',
  },
  {
    id: 3,
    url: 'https://docs.cashfree.com/docs/prestashop',
    imgPath: '/img/integrations/prestashop.png',
    integrationsName: 'PrestaShop',
  },

  {
    id: 4,
    url: 'https://docs.cashfree.com/docs/whmcs',
    imgPath: '/img/integrations/whmcs.png',
    integrationsName: 'WHMCS',
  },
  {
    id: 5,
    url: 'https://docs.cashfree.com/docs/cs-cart',
    imgPath: '/img/integrations/cs-cart.svg',
    integrationsName: 'CS Cart',
  },
  {
    id: 6,
    url: 'https://docs.cashfree.com/docs/opencart',
    imgPath: '/img/payment-gateways/opencart.svg',
    integrationsName: 'OpenCart',
  },
  {
    id: 7,
    url: 'https://docs.cashfree.com/docs/wordpress',
    imgPath: '/img/integrations/wordpress.png',
    integrationsName: 'Wordpress',
  },
  {
    id: 8,
    url: 'https://docs.cashfree.com/docs/zoho',
    imgPath: '/img/payment-gateways/zoho.svg',
    integrationsName: 'Zoho Books',
  },
  {
    id: 9,
    url: 'https://docs.cashfree.com/docs/wix',
    imgPath: '/img/payment-gateways/wix.svg',
    integrationsName: 'Wix',
  },
];

export const otherProducts = [
  {
    id: 0,
    title: 'Payment Links',
    body:
      'No-code payment links to collect payments over WhatsApp, SMS, Facebook, Twitter and other channels',
    url: 'https://www.cashfree.com/payment-links/',
    iconname: '/img/icons/payment-links.svg',
  },
  {
    id: 1,
    title: 'Subscriptions',
    body:
      'Accept recurring payments by auto-debiting customers’ accounts via standing instructions on card, e-mandate via net banking option or UPI AutoPay.',
    url: 'https://www.cashfree.com/recurring-payment/',
    iconname: '/img/icons/subscription.svg',
  },
  {
    id: 2,
    title: 'Easy Split ',
    body: 'Split vendor commissions and manage marketplace settlements',
    url: 'https://www.cashfree.com/easy-split/split-payment-gateway/',
    iconname: '/img/icons/easy-split.svg',
  },
  {
    id: 3,
    title: 'AutoCollect ',
    body:
      'Collect and reconcile IMPS/NEFT/UPI payments using unique virtual accounts and UPI IDs.',
    url: 'https://www.cashfree.com/auto-e-collect/',
    iconname: '/img/icons/auto-collect.svg',
  },
];

export const useCases = [
  {
    id: 0,
    background: '#EB5B23',
    image: {
      src: '/img/payment-gateways/ixigo.svg',
      width: '152',
      height: '152',
      alt: 'ixigo',
    },
    text: (
      <>
        <p>
          <span className="bold">With card pre-authorization</span>, ixigo is
          delighting customers with zero cancellation charges on train tickets
          when desired berth is not allotted.
        </p>
      </>
    ),
    focus: [
      {
        id: 0,
        text: 'Pre-authorization',
      },
    ],
  },
  {
    id: 1,
    background: '#273A73',
    image: {
      src: '/img/payment-gateways/dg-pay.svg',
      width: '152',
      height: '152',
      alt: 'dg-pay',
    },
    text: (
      <>
        <p>
          With <span className="bold">instant settlements</span> DG Pay, the
          lending platform is using its own loan collections to disburse loans
          with reduced dependency on additional credit.
        </p>
      </>
    ),
    focus: [
      {
        id: 0,
        text: 'Instant settlements',
      },
    ],
  },

  {
    id: 2,
    background: '#FC2779',
    image: {
      src: '/img/icons/nykaa.svg',
      width: '152',
      height: '152',
      alt: 'nykaa',
    },
    text: (
      <>
        <p>
          Nykaa is winning customer trust and loyalty more than ever before by
          doing <span className="bold">instant refunds</span> using Payouts.
        </p>
      </>
    ),
    focus: [
      {
        id: 0,
        text: 'Instant refunds',
      },
    ],
  },
];

export const bestPaymentGateway = [
  {
    id: 0,
    content: (
      <>
        PhonePe, Paytm, BHIM, GooglePay, all{' '}
        <span style={{ color: '#6933d3' }}>
          other UPI applications supported
        </span>
      </>
    ),
  },
  {
    id: 1,
    content: (
      <>
        Save UPI ID option for{' '}
        <span style={{ color: '#6933d3' }}>repeat customers</span>
      </>
    ),
  },
  {
    id: 2,
    content: (
      <>
        <span style={{ color: '#6933d3' }}>Auto-population of UPI ID </span>
        based on mobile number and selected UPI app
      </>
    ),
  },
  {
    id: 3,
    content: <>Auto UPI ID validation at checkout</>,
  },
];

export const lowPricingChecklist = [
  {
    id: 0,
    listItem: 'No setup, maintenance or any other hidden fees',
  },
  {
    id: 1,
    listItem: 'Pay only for actual transactions',
  },
  {
    id: 2,
    listItem: 'Real-time transaction fees reporting',
  },
  {
    id: 3,
    listItem: 'Paperless and same day onboarding',
  },
];
